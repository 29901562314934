<template>
  <div class="position-relative pb-1">
    <div v-if="loading" class="loader-overlay">
      <b-spinner style="width: 4rem; height: 4rem" variant="primary" />
    </div>
    <b-card title="Send Notification">
      <form
        ref="NotificationForm"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="submit"
      >
        <validation-observer ref="notificationInfo" tag="form">
          <b-row>
            <b-col md="12">
              <b-form-group label="Notification content En" label-for="Notification content En">
                <validation-provider
                  #default="{ errors }"
                  name="Notification content En"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="value.body"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Notification content En"
                    rows="5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


            <b-col md="12">
              <b-form-group label="Notification content Fr" label-for="Notification content Fr">
                <validation-provider
                  #default="{ errors }"
                  name="Notification content Fr"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="value.bodyFr"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Notification content Fr"
                    rows="5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button
          id="show-btn"
          @click="showSendPopup()"
          class="float-right"
          variant="primary"
          :disabled="loading"
          >Send</b-button
        >
      </form>
    </b-card>

    <Modal
      :text="modalOptions.text"
      :ok-button="modalOptions.okButton"
      :cancel-button="modalOptions.cancelButton"
      :show="show"
      @result="modalOptions.modalResult"
      @hidden="show = false"
    />
  </div>
</template>
  
  <script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Modal from "@/views/components/shared/Modal/Modal";

import {
  BFormGroup,
  BFormInput,
  BCard,
  BRow,
  BFormInvalidFeedback,
  BCol,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";

export default {
  components: {
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    TabContent,
    vSelect,
    BFormInvalidFeedback,
    BSpinner,
    BCard,
    BInputGroupAppend,
    Modal,
    BFormCheckbox,
  },
  props: ["value", "action"],
  data() {
    return {
      loading: false,
      formData: null,
      required,
      modalOptions: {
        modalResult: this.modalResultRemove,
      },
      show: false,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    submit() {
      this.$refs.notificationInfo.validate().then((success) => {
        if (success) {
          this.loading = true;
          const formData = new FormData();
          formData.append("body", this.value.body);
          formData.append("bodyFr", this.value.bodyFr);
          this.$emit("formSubmitted", formData);
        } else {
        }
      });
    },
    showSendPopup() {
      this.show = true;
      this.modalOptions = {
        text: `Are you sure you want to send notification ?`,
        okButton: `Send`,
        cancelButton: "Cancel",
        currentAction: "",
        modalResult: this.submit,
      };
    },
  },
};
</script>
<style>
.profile-img {
  width: 140px;
  height: 140px;
  border-radius: 1.357rem;
  object-fit: cover;
  cursor: pointer;
  border: groove;
}
</style>
