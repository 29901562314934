<template>
  <div class="">
    <NotificatioForm ref="NotificatioForm" v-model="formData" @formSubmitted="storeNotification"/>
  </div>
</template>

<script>
import NotificatioForm from "@/views/components/notification/NotificationForm";
import NotificationService from "@/services/NotificationService";

export default {
  name: "CreateNotification",
  components: {
    NotificatioForm
  },
  data() {
    return {
      NotificationService: new NotificationService(),
      formData: {
        // what key i have
      },
    }
  },
  methods: {
    storeNotification(e) {
      this.NotificationService.create(e).then(res => {
        this.$router.go()
        // window.location.href = 'home'
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
</style>
